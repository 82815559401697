export function formatLargeNumber(largeNumber: number): string {
	return new Intl.NumberFormat("da-DK").format(largeNumber);
}

export function addPlusIfPositiveNumber(number: number): string {
	if (number <= 0)
		return number.toString();

	return "+" + number;
}

export function toDanishPriceFormat(number: number | undefined | null) {
	if (number === undefined || number === null) {
		return "";
	}

	const formatter = new Intl.NumberFormat('da-DK', {
		style: 'currency',
		currency: 'DKK',
		currencyDisplay: 'code',
		minimumFractionDigits: Number.isInteger(number) ? 0 : 2
	});
	return formatter.format(number);
}

export function toDanishPriceFormatNoCurrency(number: number | undefined | null) {
	if (number === undefined || number === null) {
		return "";
	}
	const formatter = new Intl.NumberFormat('da-DK', {
		currency: 'DKK',
		minimumFractionDigits: Number.isInteger(number) ? 0 : 2
	});
	return formatter.format(number);
}

export function formatNumber(number: number | undefined | null) {
	if (!number) {
		return "0";
	}

	const formatter = new Intl.NumberFormat('da-DK', {
		minimumFractionDigits: 0
	});
	return formatter.format(number);
}

export function formatTransactionsState(state: number) {
	switch (state) {
		case 0:
			return "Awaiting accept";

		case 1:
			return "Declined";

		case 2:
			return "CounterOffer";

		case 10:
			return "Accepted";

		case 14:
			return "PaymentAuthorized";

		case 15:
			return "PaymentCaptured";

		case 20:
			return "Shipping";

		case 30:
			return "Completed";

		case 40:
			return "Cancelled";

		default:
			return "Unkown State"
	}
}