import { webClient } from "@api/web/WebClient";
import QrCode from "@components/admin/QrCode";
import Logo from "@components/Logo";
import { withWebLayout } from "@components/web/Decorators";
import DownloadAppButton from "@components/web/DownloadAppButton";
import { useLocalization } from "@hooks/localization";
import { useQueryParameter } from "@hooks/navigation";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Link from "@material-ui/core/Link";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import { PaymentType, WebTransactionForReceiptGetResponse, WebTransactionForReceiptItemSnapshotGetResponse } from "@reshopper/web-client";
import { toDanishPriceFormat } from "@utils/formatting";
import { toDanishDateFormat } from "@utils/miscellaneous";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import styles from "./index.module.scss";

export default withWebLayout(() => {
	const [transactionShortIdOrId] = useQueryParameter("id");
	const [transaction, setTransaction] = useState<WebTransactionForReceiptGetResponse>();
	const [isPageLoading, setIsPageLoading] = useState<boolean>(true);
	const [isSellerPopUpStore, setIsSellerPopUpStore] = useState<boolean>(false);
	const t = useLocalization();

	useEffect(() => {
		const fetchTransaction = async () => {
			try {
				const transactionResponse = await webClient().webTransactionForReceiptGet(transactionShortIdOrId);
				if (transactionResponse.id != null) {
					setTransaction(transactionResponse);

					if (transactionResponse?.sellerDetails?.userBusinessType === "popupOutlet") {
						setIsSellerPopUpStore(true);
					}
					else {
						setIsSellerPopUpStore(false);
					}
				}
			}
			catch (ex) {
				setIsPageLoading(false);
			}
		}
		fetchTransaction();
	}, [transactionShortIdOrId]);

	const round = (value: number, step: number) => {
		step || (step = 1.0);
		const inv = 1.0 / step;
		let rounded = Math.round(value * inv) / inv;
		if (rounded === 0) {
			rounded = Math.abs(rounded);
		}
		return rounded;
	}

	const roundAmountForLastCashOrVoucherPaymentForReceipt = (paymentType: PaymentType, paymentAmountInHundreds: number, lastAddedPayment: boolean) => {
		if ((paymentType === "cash" || paymentType === "giftCard" || paymentType === "creditVoucher") && lastAddedPayment) {
			const roundedPaymentAmountInHundreds = round((paymentAmountInHundreds / 100), 0.5) * 100;
			const diff = paymentAmountInHundreds - roundedPaymentAmountInHundreds;

			if (diff <= 24) {
				return toDanishPriceFormat(roundedPaymentAmountInHundreds / 100);
			}
		}
		return toDanishPriceFormat(paymentAmountInHundreds / 100);
	}


	return <Box pt={2}>
		<Helmet title={"Reshopper"}>
			<meta name="robots" content="noindex, nofollow" />
		</Helmet>

		{!transaction ?
			<>{isPageLoading ?
				<Box textAlign="center">
					<CircularProgress />
				</Box>
				:
				<Box className={styles.noReceiptContainer}>
					<div>
						<Logo variant="text-black" href="/" />
					</div>
					<Typography align="center" style={{ marginTop: 5, marginBottom: 6 }}>
						{t("Receipts_NotFound")}
					</Typography>
					<div>
						<Link
							href="/"
							style={{ cursor: "pointer", fontSize: "15px" }}>
							{t("LinkText_Go_To_Frontpage")}
						</Link>
					</div>
				</Box>
			}
			</>
			:
			<Box className={styles.receipt}>
				<Box className={styles.receiptHeader}>
					<div>
						<Logo variant="text-black" href="/" />
					</div>
					<div className={styles.address}>
						{transaction.sellerDetails?.userAddressSnapshot.street} <br />
						{transaction.sellerDetails?.userAddressSnapshot.zipCode} {transaction.sellerDetails?.userAddressSnapshot.city}
						<div>{transaction.sellerDetails?.cvr}</div>
						<DownloadAppButton
							showAsLink
							style={{
								fontSize: "12px"
							}} />
					</div>
				</Box>
				{transaction.items.map((item, index) =>
					<ReceiptOrderLine
						key={`${index}-${item.itemId}`}
						index={index}
						item={item}
					/>
				)}
				{transaction.bid.payment.shipping > 0 &&
					<TableContainer>
						<Table>
							<TableBody>
								{transaction.bid.payment.hasFreeShipping ?
									<TableRow>
										<TableCell>
											<div>Fragt</div>
										</TableCell>
										<TableCell align="right">
											<div style={{ textDecoration: "line-through" }}>
												{toDanishPriceFormat(transaction.bid.payment.shipping / 100)}
											</div>
											<div>
												{toDanishPriceFormat(0)}
											</div>
										</TableCell>
									</TableRow>
									:
									<TableRow>
										<TableCell>
											<div>Fragt</div>
										</TableCell>
										<TableCell align="right">
											<div>
												{toDanishPriceFormat(transaction.bid.payment.shipping / 100)}
											</div>
										</TableCell>
									</TableRow>
								}
							</TableBody>
						</Table>
					</TableContainer>
				}
				<Box className={styles.receiptBottomSection}>
					<TableContainer>
						<Table>
							<TableBody>
								<TableRow className={styles.totalRow}>
									<TableCell>
										Total
									</TableCell>
									<TableCell align="right">
										{toDanishPriceFormat(transaction.bid.totalAmountForTransactionInHundreds / 100)}
									</TableCell>
								</TableRow>
								{transaction.bid.payment.paymentMethods.map((payment, index) => (
									<TableRow key={index}>
										<TableCell>
											{GetPaymentType(payment.paymentType)}
										</TableCell>
										<TableCell align="right">
											{toDanishPriceFormat(payment.amountInHundreds / 100)}
										</TableCell>
									</TableRow>
								))}
								<TableRow>
									<TableCell>Moms udgør</TableCell>
									<TableCell align="right">
										{toDanishPriceFormat(((transaction.bid.totalAmountForTransactionInHundreds / 100) / 100) * 20)}
									</TableCell>
								</TableRow>
								{transaction.bid.payment.refundedAmountInHundreds > 0 &&
									<TableRow className={styles.refundInfoRow}>
										<TableCell>
											<b>Refunderet total</b>
										</TableCell>
										<TableCell align="right">
											<b>{toDanishPriceFormat((transaction.bid.payment.refundedAmountInHundreds / 100) * -1)}</b>
										</TableCell>
									</TableRow>
								}
							</TableBody>
						</Table>
						<Table className={styles.receiptExtraInfoTable}>
							<TableBody>
								<TableRow>
									<TableCell colSpan={2} className={styles.extraInfo}>
										<Box>
											<Box className={styles.qrContainer}>
												<QrCode text={`reshopper://t/${transaction.id}`} link={`reshopper://t/${transaction.id}`} size={60} />
											</Box>
											<Typography className={styles.idInfo}>ID: {transaction.id}</Typography>
											<Typography noWrap>
												{toDanishDateFormat(transaction.createdUtc)}
											</Typography>
										</Box>
									</TableCell>
								</TableRow>
								{isSellerPopUpStore &&
									<TableRow className={styles.onlyPopupStoreInfo}>
										<TableCell colSpan={2}>
											<Box>
												I Reshopper Pop-up kan varen byttes indenfor 14 dage,<br />
												men der gives ikke penge retur.<br />
												Husk kvittering!
											</Box>
										</TableCell>
									</TableRow>
								}
							</TableBody>
						</Table>
					</TableContainer>
				</Box>
			</Box>
		}
	</Box>
});

function ReceiptOrderLine(props: {
	item: WebTransactionForReceiptItemSnapshotGetResponse
	index: number
}) {
	return <Box
		className={styles.receiptOrderLine}
		color="primary.main">
		<ReceiptItemAvatar item={props.item} />
		<ReceiptItemDescription item={props.item} />
		<ReceiptItemPrice item={props.item} />
	</Box>
}

const ReceiptItemAvatar = (props: {
	item: WebTransactionForReceiptItemSnapshotGetResponse
}) => {
	const imageUrl = props.item.image ? props.item.image.url : "/images/logo-icon-white.svg";

	return <Box className={styles.orderLineImageContainer}>
		<Avatar alt={`${props.item.brandOrTitle} ${props.item.description}`} src={imageUrl} />
	</Box>
}

const ReceiptItemDescription = (props: {
	item: WebTransactionForReceiptItemSnapshotGetResponse
}) => {

	return <Box style={{ minWidth: "75px", flexGrow: "2" }}>
		<div>
			<Typography noWrap>{props.item.brandOrTitle}</Typography>
			<Typography noWrap>{props.item.description}</Typography>
			<Typography noWrap className={styles.orderLineSizeOrAge} style={{ fontSize: "12px" }}>
				{(props.item.size || props.item.age) ? props.item.size ? `Str. ${props.item.size}` : `${props.item.age} år` : ""}
			</Typography>
			{!!props.item.refundedAtUtc &&
				<Typography><i>{`Refunderet d. ${toDanishDateFormat(props.item.refundedAtUtc, "PP")}`}</i></Typography>
			}
		</div>
	</Box>
}

const ReceiptItemPrice = (props: {
	item: WebTransactionForReceiptItemSnapshotGetResponse
}) => {

	return <Box>
		{props.item.refundedAtUtc ?
			<>
				{props.item.discountDetails && props.item.discountDetails.appliedItemDiscount ?
					<Typography className={`${styles.itemPrice} ${styles.lineThrough}`}>
						{toDanishPriceFormat(+props.item.discountDetails.appliedItemDiscount.discountedPriceInHundreds / 100)}
					</Typography>
					:
					<Typography className={`${styles.itemPrice} ${styles.lineThrough}`}>
						{toDanishPriceFormat(+props.item.priceInHundreds / 100)}
					</Typography>
				}
			</>
			:
			<>
				{props.item.discountDetails && props.item.discountDetails.appliedItemDiscount ?
					<>
						<Typography className={`${styles.itemPrice} ${styles.lineThrough}`}>
							{toDanishPriceFormat(+props.item.priceInHundreds / 100)}
						</Typography>

						<Typography className={styles.itemPrice}>
							{toDanishPriceFormat(+props.item.discountDetails.appliedItemDiscount.discountedPriceInHundreds / 100)}
						</Typography>
					</>
					:
					<Typography className={styles.itemPrice}>
						{toDanishPriceFormat(+props.item.priceInHundreds / 100)}
					</Typography>
				}
			</>
		}
	</Box>
}


function GetPaymentType(
	paymentType: string
) {
	let returnPaymentType = paymentType;

	switch (returnPaymentType) {
		case "card":
			returnPaymentType = "Kreditkort";
			break;
		case "mobilePay":
			returnPaymentType = "MobilePay";
			break;
		case "giftCard":
			returnPaymentType = "Gavekort";
			break;
		case "creditVoucher":
			returnPaymentType = "Tilgodebevis";
			break;
		case "cash":
			returnPaymentType = "Kontant";
			break;
		case "reshopperCard":
			returnPaymentType = "Betalt med profil";
	}
	return <>{returnPaymentType}</>
}