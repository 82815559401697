

export default (props: { 
    text: string,
    link?: string
    size?: number,
}) => {
    const qrCodeUrl = `https://barcode.tec-it.com/barcode.ashx?data=${props.text}&code=MobileQRCode&dpi=200&dataseparator=&download=false`;
    return <>
        <a href={props.link} target="_blank" rel="noopener noreferrer">
            <img src={qrCodeUrl} alt="QR Code for scanning" style={{
                width: props.size || 200,
                height: props.size || 200
            }} />
        </a>
    </>;
}